<template>
  <div class="lawyerItem">
    <div class="betweenFlex wrapTopGuanzhu">
      <div class="commonFlex">
        <div class="leftImg">
          <div class="lawyerImg commonFlex">
            <img :src="require('@as/reading/test.png')" alt="" />
          </div>

          <div class="commonFlex wrapFlag">
            <img :src="require('@as/reading/personFlag.png')" alt="" />
          </div>
        </div>
        <div class="rightName">
          <div class="name">恒庆律师事务所</div>
          <div class="wrapJigouname">
            <img :src="require('@as/huangye/juxing.png')" alt="" style="" />
            <span class="jigouName">汇律机构</span>
          </div>
        </div>
      </div>
      <div class="jiaguanzhu betweenFlex">
        <span>关注</span><span class="commonFlex">+</span>
      </div>
    </div>
    <div class="introduce">
      中伦律师事务所于1989年创立于北京，是中国最早的合伙制律师事务所之一...
    </div>
    <div class="commonFlex wrapFourCount">
      <div class="hasRightBorder">文章: 369</div>
      <div class="hasRightBorder">活动: 36</div>
      <div class="hasRightBorder">招聘: 36</div>
      <div>关注: 36</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.lawyerItem {
  padding: 20px;
  padding-bottom: 0;
  width: 385px;

  // height: 220px;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #dedede;
  border-radius: 2px;
  margin-right: 22px;
  margin-top: 23px;
  .introduce {
    margin-top: 31px;
    // padding-bottom: 22px;

    line-height: 24px;
    font-size: 14px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #666666;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .wrapFourCount {
    height: 59px;
    margin-top: 22px;
    border-top: 1px solid #dedede;
    div {
      height: 30px;
      width: 90px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #666666;
    }
    .hasRightBorder {
      border-right: 1px solid #dedede;
    }
  }
  .wrapTopGuanzhu {
    align-items: flex-start !important;
  }
  .jiaguanzhu {
    // width: 56px;
    padding: 5px 12px;
    // height: 22px;
    background: #1c9ab1;
    border-radius: 11px;

    font-size: 12px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
  }
  .rightName {
    margin-left: 20px;
    .naem {
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
    }
    .wrapJigouname {
      position: relative;
      margin-top: 10px;
    }

    .jigouName {
      position: absolute;
      top: 3px;
      left: 7px;
      font-size: 10px;
      font-family: PingFangSC;
      font-weight: 300;
      color: #1c9ab1;
    }
  }
  .leftImg {
    position: relative;
  }
  .wrapFlag {
    width: 14px;
    height: 14px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .lawyerImg {
    width: 56px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 50%;
    img {
      width: 32px;
      height: 36px;
    }
  }
}
</style>